var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { staticClass: "tour-publication-1" },
    [
      _c("PlanUsageAlert", { attrs: { target: "participant_questionnaire" } }),
      _c("vx-tour", {
        attrs: { name: "questionnairePublicationIntroSteps", auto: true },
      }),
      _c("div", { staticClass: "grid sm:flex tour-publication-2" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full sm:w-10/12 break-all text-center sm:text-left flex items-center",
          },
          [
            _c("label", { staticClass: "mr-4 tour-publication-3" }, [
              _c("b", [_vm._v(_vm._s(_vm.$t("link-do-questionario")))]),
            ]),
            _c("div", { staticClass: "tour-questionnaire-publication" }, [
              _vm._v(_vm._s(_vm.shortUrl)),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "w-full sm:w-2/12 flex justify-center sm:justify-start ml-0 sm:ml-4 mt-2 sm:mt-0",
          },
          [
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.shortUrl,
                    expression: "shortUrl",
                    arg: "copy",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:success",
                    value: _vm.onSucceddCopy,
                    expression: "onSucceddCopy",
                    arg: "success",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:error",
                    value: _vm.onErrorCopy,
                    expression: "onErrorCopy",
                    arg: "error",
                  },
                ],
                staticClass: "footer-button",
                attrs: { type: "filled", icon: "content_copy" },
              },
              [_vm._v(_vm._s(_vm.$t("copiar")))]
            ),
          ],
          1
        ),
      ]),
      _c("h5", { staticClass: "mt-6 border-bottom" }, [
        _vm._v(_vm._s(_vm.$t("convidar-participantes"))),
      ]),
      _c("div", { staticClass: "vx-row flex items-end mt-4" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mb-2" },
          [
            _c("emails-input", {
              ref: "emailsInput",
              staticClass: "tour-publication-6",
              attrs: { buttonText: "Adicionar" },
              on: {
                tagsSent: _vm.addEmails,
                importStudents: _vm.importStudents,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row flex items-end mt-4" }, [
        _c(
          "div",
          { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
          [
            _c("questionnaire-publication-grid", {
              ref: "emailListGrid",
              attrs: {
                noDataText: "Você ainda não adicionou nenhum email.",
                type: "email",
                id: _vm.content.id,
              },
              on: {
                selected: _vm.changedEmailSelection,
                remove: _vm.removeEmailSent,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "grid grid-cols-1 sm:grid-cols-2" }, [
        _c(
          "div",
          {
            staticClass: "font-bold text-center sm:text-left flex items-center",
          },
          [
            _vm.publicated
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        `Questionário publicado em ${this.$utils.format.dateWithFormat(
                          _vm.publicated,
                          "DD/MM/YYYY - kk:mm:ss"
                        )}`
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "font-bold flex justify-center sm:justify-end mt-4 sm:mt-0",
          },
          [
            !_vm.publicated
              ? _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "questionnaires.release",
                        expression: "'questionnaires.release'",
                      },
                    ],
                    staticClass: "tour-publication-7",
                    attrs: { icon: "send" },
                    on: { click: _vm.confirmSend },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("liberar-e-enviar-email")) + " ")]
                )
              : _vm._e(),
            _vm.publicated
              ? _c("button-alternates", {
                  attrs: {
                    iconMain: "send",
                    label: _vm.$t("reenviar-convite"),
                    buttonClass: "px-4",
                    alternatives: [
                      {
                        icon: "send",
                        label: _vm.$t("reenviar-convite-pendente"),
                        color: "warning",
                        click: () => {
                          _vm.confirmSendWithStatus("p")
                        },
                      },
                    ],
                  },
                  on: {
                    "click-main": function ($event) {
                      return _vm.confirmSend()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }