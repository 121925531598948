var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Grid", {
    ref: "emailsGrid",
    attrs: {
      direction: "asc",
      noDataText: _vm.noDataText,
      service: _vm.contentQuestionnaireService,
      route_name: "questionnaireEmailGrid",
      route_grid_path: `/${_vm.id}/emails/${_vm.type}/grid`,
      hide_actions: true,
      showTotalText: "Total",
    },
    scopedSlots: _vm._u([
      {
        key: "gridThead",
        fn: function () {
          return [
            _c(
              "div",
              {
                key: "selected",
                staticStyle: { "margin-top": "10px" },
                attrs: { width: "5%" },
              },
              [
                _c("vs-checkbox", {
                  model: {
                    value: _vm.headerSelected,
                    callback: function ($$v) {
                      _vm.headerSelected = $$v
                    },
                    expression: "headerSelected",
                  },
                }),
              ],
              1
            ),
            _c(
              "vs-th",
              {
                key: "user_name",
                attrs: { "sort-key": "email", width: "40%" },
              },
              [_vm._v(_vm._s(_vm.$t("name")))]
            ),
            _c(
              "vs-th",
              { key: "email", attrs: { "sort-key": "email", width: "40%" } },
              [_vm._v(_vm._s(_vm.$t("email")))]
            ),
            _c(
              "vs-th",
              { key: "status", attrs: { "sort-key": "status", width: "15%" } },
              [_vm._v(_vm._s(_vm.$t("status")))]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "gridData",
        fn: function (props) {
          return _vm._l(props.data, function (tr, i) {
            return _c(
              "vs-tr",
              { key: props.data[i], class: _vm.formatCssRow(tr) },
              [
                _c(
                  "vs-td",
                  { key: "selected" },
                  [
                    _c("vs-checkbox", {
                      attrs: { "vs-value": tr.id },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    }),
                  ],
                  1
                ),
                _c("vs-td", { key: "user_name" }, [
                  _vm._v(_vm._s(tr.user_name)),
                ]),
                _c("vs-td", { key: "email" }, [_vm._v(_vm._s(tr.email))]),
                _c("vs-td", { key: "status" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      tr.status === "s"
                        ? _c("vs-icon", {
                            staticClass: "text-2xl pr-2",
                            attrs: { icon: "check_circle" },
                          })
                        : _vm._e(),
                      tr.status === "o"
                        ? _c("vs-icon", {
                            staticClass: "text-2xl pr-2",
                            attrs: { icon: "timer" },
                          })
                        : _vm._e(),
                      tr.status === "p"
                        ? _c("vs-icon", {
                            staticClass: "text-2xl pr-2 icon-cursor",
                            attrs: { icon: "delete" },
                            on: {
                              click: function ($event) {
                                return _vm.remove(tr)
                              },
                            },
                          })
                        : _vm._e(),
                      tr.status === "e"
                        ? _c("vs-icon", {
                            staticClass: "text-2xl pr-2 icon-cursor",
                            attrs: { icon: "delete" },
                            on: {
                              click: function ($event) {
                                return _vm.remove(tr)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(tr.status_fmt))]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          })
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }